@import 'ember-paper';


.header-wrapper {
    margin-bottom: 5px;
    border: 1px solid black;
}

.header-1 {
    height: 5px;
    width: 100%;
    background-color: black;
}

.header-2 {
    height: 5px;
    width: 100%;
    background-color: lightblue;
}

.header-3 {
    height: 5px;
    width: 100%;
    background-color: red;
}

.header-4 {
    height: 5px;
    width: 100%;
    background-color: lightgreen;
}

.header-5 {
    height: 5px;
    width: 100%;
    background-color: yellow;
}

.spacing-right {
    margin-right: 10px;
}

.interval-button {
    margin-top: 10px;

    margin-bottom: 10px;
    height: 50px;
    width: 200px;   
}

.time-box {
    padding: 5px;
    margin: 5px;
    border: 1px solid gray;
}

.time-box-left {
    width: 50%;
    height: 50vh;
    overflow-y: scroll;
}

.time-box-right {
    width: 40%;
    height: 50vh;
}

.m-x-auto {
    margin-left: auto;
    margin-right: auto;
}